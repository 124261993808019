var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('van-sticky',[_c('van-nav-bar',{staticClass:"retreat",attrs:{"title":"configurar","left-arrow":""},on:{"click-left":_vm.onClickLeft}})],1),_c('div',[_vm._m(0),(false)?_c('div',{staticClass:"cells"},[_vm._m(1),_vm._m(2)]):_vm._e(),_vm._m(3),_c('div',{staticClass:"cells",on:{"click":function($event){return _vm.toyingsixieyi()}}},[_c('div',{staticClass:"cellsLeft"},[_vm._m(4),_c('div',[_c('a',{attrs:{"href":_vm.url,"target":"_blank"}},[_vm._v("politica de Privacidade")])])]),_vm._m(5)])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cells"},[_c('div',{staticClass:"cellsLeft"},[_c('div',[_c('img',{attrs:{"src":require("../../assets/检查更新.png"),"alt":""}})]),_c('div',[_vm._v("Verifique atualizações")])]),_c('div',{staticClass:"cell-right"},[_c('div',[_vm._v("AtualV2.2.5")]),_c('img',{attrs:{"src":require("../../assets/ic_forward.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cellsLeft"},[_c('div',[_c('img',{attrs:{"src":require("../../assets/清除缓存.png"),"alt":""}})]),_c('div',[_vm._v("Limpar cache")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cell-right"},[_c('div'),_c('img',{attrs:{"src":require("../../assets/ic_forward.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cells"},[_c('div',{staticClass:"cellsLeft"},[_c('div',[_c('img',{attrs:{"src":require("../../assets/评分.png"),"alt":""}})]),_c('div',[_vm._v("Pontuação de aplicação")])]),_c('div',{staticClass:"cell-right"},[_c('img',{attrs:{"src":require("../../assets/ic_forward.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("../../assets/隐私政策.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cell-right"},[_c('img',{attrs:{"src":require("../../assets/ic_forward.png"),"alt":""}})])
}]

export { render, staticRenderFns }
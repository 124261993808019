<template>
    <div class="">
        <van-sticky>
            <van-nav-bar title="configurar" left-arrow @click-left="onClickLeft" class="retreat">
            </van-nav-bar>
        </van-sticky>
        <div>
            <div class="cells">
                <div class="cellsLeft">
                    <div><img src="../../assets/检查更新.png" alt=""></div>
                    <div>Verifique atualizações</div>
                </div>
                <div class="cell-right">
                    <div>AtualV2.2.5</div>
                    <img src="../../assets/ic_forward.png" alt="">
                </div>
            </div>
            <div class="cells" v-if="false">
                <div class="cellsLeft">
                    <div><img src="../../assets/清除缓存.png" alt=""></div>
                    <div>Limpar cache</div>
                </div>
                <div class="cell-right">
                    <div></div>
                    <img src="../../assets/ic_forward.png" alt="">
                </div>
            </div>
            <div class="cells">
                <div class="cellsLeft">
                    <div><img src="../../assets/评分.png" alt=""></div>
                    <div>Pontuação de aplicação</div>
                </div>
                <div class="cell-right">
                    <img src="../../assets/ic_forward.png" alt="">
                </div>
            </div>
            <div class="cells" @click="toyingsixieyi()">
                <div class="cellsLeft">
                    <div><img src="../../assets/隐私政策.png" alt=""></div>
                    <div><a :href="url" target="_blank">politica de Privacidade</a></div>
                </div>
                <div class="cell-right">
                    <img src="../../assets/ic_forward.png" alt="">
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { getfankui } from '@/api/Mue/index'
export default {
    name: '',
    components: {},
    data() {
        return {
            url:null,
        }
    },
    // 计算属性
    computed: {},
    watch: {},
    mounted() {
        this.toyingsixieyi()
     },
    methods: {
        onClickLeft() {
            this.$router.go(-1)
        },
        toyingsixieyi() {
            getfankui({ conf_key: "privacy_url" }).then(res=>{
                this.url = res.result
            })
        }
    }
}
</script>
<style lang="scss" scoped>
@import '../../style/meu.css';

.record {
    height: 100vh;
    background-color: var(--title-color);
}

/deep/.van-nav-bar,a {
    background-color: var(--main-color);
    font-family: PingFang SC;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 16px;
    letter-spacing: 0px;
    color: var(--title-color);
}

/deep/.van-nav-bar .van-icon {
    opacity: 0.8;
    font-size: 18px;
    color: var(--title-color);
}

/deep/.van-nav-bar__title {
    opacity: 0.8;
    font-size: 18px;
    color: var(--title-color);
}

.cells {
    margin-top: 20px;
}

.cell-right {
    font-size: 6px;
    display: flex;
    align-items: center;
}

.cell-right img {
    width: 8px;
    margin-left: 5px;
}
</style>
